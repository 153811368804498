import { HealthInsuranceCPMSPage, PageTitle, useScreenType } from '@orus.eu/pharaoh'
import type { ReactElement } from 'react'
import santeclairDesktop from './santeclair-desktop.png'
import santeclairMobile from './santeclair-mobile.png'

export default function HealthInsuranceSanteclairPage(): ReactElement {
  const screenType = useScreenType()

  return (
    <>
      <PageTitle title="Le réseau Santéclair" fullWidth />

      <HealthInsuranceCPMSPage
        text="Envoyez-nous vos devis dans leur globalité, ils seront traités dans les meilleurs délais, dès que l’estimation est faite on vous prévient. "
        src={screenType === 'mobile' ? santeclairMobile : santeclairDesktop}
        href="https://www.cpms.fr/connexion-particulier/"
        persistentNotificationText="Connectez-vous à Santéclair avec vos identifiants qui vous ont été envoyés."
        buttonText="Accéder à Santéclair"
      />
    </>
  )
}
